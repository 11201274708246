import React, { ComponentProps } from 'react'
import './Button.scss'

import Arrow from '../../Images/arrow.svg'
import ArrowGreen from '../../Images/arrow-green.svg'

interface ButtonProps extends ComponentProps<'a'> {
  text: string
  extraClasses?: string
}

export const Button = ({ text, extraClasses, ...props }: ButtonProps) => {
  return (
    <a {...props} className={`button ${extraClasses}`}>
      <div className='arrow-wrapper'>
        <img className='white' src={Arrow} alt='arrow' />
        <img className='green' src={ArrowGreen} alt='arrow' />
      </div>
      <span>{text}</span>
    </a>
  )
}
