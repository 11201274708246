import React from 'react'
import './CopyRight.scss'

interface CopyRightProps {
  text: string
}

export const CopyRight = ({ text }: CopyRightProps) => {
  return <span className='copy-right'>{text}</span>
}
