import React from 'react'
import { useAppContext } from '../../../context/AppContext'
import Close from '../../../Images/close.svg'
import './CloseButton.scss'

interface CloseButtonProps {
  direction: 'left' | 'right'
}

export const CloseButton = ({ direction }: CloseButtonProps) => {
  const { setSelectedPoint } = useAppContext()
  return (
    <button
      data-direction={direction}
      onClick={() => {
        setSelectedPoint(-1)
      }}
      className='close-btn'
    >
      <img src={Close} alt='Close' />
    </button>
  )
}
