import React, { useEffect } from 'react'
import { AnimatePresence, Variants, motion } from 'framer-motion'
import { useAppContext } from '../../context/AppContext'
import data from '../../data/data'
import { PointDataType } from '../AnimalMap/Points/Point'

import './Modal.scss'
import { CloseButton } from './CloseButton/CloseButton'
import {
  defaultTransition,
  opacity_variant,
  opacity_y
} from '../../utils/transition'
import { useWindowSize } from 'usehooks-ts'

import modalData from '../../data/modalData.json'
import { customSanitize } from '../../utils/customSanitize'
import { AudioButton } from './AudioButton/AudioButton'
import { Slider } from './Slider/Slider'
import { VideoPlayer } from './VideoPlayer/VideoPlayer'

export const Modal = () => {
  const { selectedPoint } = useAppContext()
  const point = data[selectedPoint]

  useEffect(() => {
    const html = document.querySelector<HTMLElement>('html')

    if (selectedPoint !== -1) {
      if (html) html.style.overflow = 'hidden'
    } else {
      if (html) html.style.overflow = 'auto'
    }
  }, [selectedPoint])

  return (
    <AnimatePresence mode='wait'>
      {point && <ModalWrapper key={selectedPoint} point={point} />}
    </AnimatePresence>
  )
}

interface ModalWrapperProps {
  point: PointDataType
}

const ModalWrapper = ({ point }: ModalWrapperProps) => {
  const { width } = useWindowSize()
  const isTouch = width > 0 && width < 1024
  const left = point ? point.left_num : 0
  const direction = left > 50 || isTouch ? 'left' : 'right'

  // direction === 'left' ? inner_variant_x_left : inner_variant_x_right

  const modalInfo = point ? modalData.find((x) => x.id === point.id) : null

  const [enableMedia, setEnableMedia] = React.useState(false)

  const { setSelectedPoint } = useAppContext()

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement
      if (!target.closest('.modal') && !target.closest('.point')) {
        setSelectedPoint(-1)
      }
    }

    window.addEventListener('click', handleClick)

    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [])

  return (
    <motion.div
      variants={direction === 'left' ? variant_left : variant_right}
      initial='initial'
      animate='animate'
      exit='exit'
      data-direction={direction}
      className='modal'
      onAnimationComplete={(state) => {
        if (state === 'animate') {
          setEnableMedia(true)
        }
      }}
    >
      <CloseButton direction={direction} />
      {modalInfo && (
        <div className='modal-info-wrapper'>
          <div className='information-wrapper'>
            <motion.span variants={opacity_y} className='upper-line'>
              {modalInfo['upper-line']}
            </motion.span>
            <motion.h2 variants={opacity_y}>{modalInfo.title}</motion.h2>
            <motion.div
              variants={opacity_y}
              className='content'
              dangerouslySetInnerHTML={{
                __html: customSanitize(modalInfo.content)
              }}
            />
            {modalInfo.audio && <AudioButton audio={modalInfo.audio} />}
          </div>
          <motion.div style={{ minHeight: 300 }} className='is-relative'>
            {enableMedia ? (
              <motion.div
                className='media-wrapper'
                initial='initial'
                animate='animate'
              >
                {Array.isArray(modalInfo.images) &&
                  modalInfo.images.length > 0 && (
                    <motion.div variants={opacity_variant}>
                      <Slider
                        images={modalInfo.images}
                        enableSlider={enableMedia}
                      />
                    </motion.div>
                  )}

                {modalInfo.video.video && (
                  <VideoPlayer video={modalInfo.video} />
                )}
              </motion.div>
            ) : (
              <span className='spinner'></span>
            )}
          </motion.div>
        </div>
      )}
    </motion.div>
  )
}
const variant_left: Variants = {
  initial: {
    x: '-100%'
  },
  animate: {
    x: 0,
    transition: {
      ...defaultTransition,
      staggerChildren: 0.2
    }
  },
  exit: {
    x: '-100%',
    transition: {
      ...defaultTransition
    }
  }
}
const variant_right: Variants = {
  initial: {
    x: '100%'
  },
  animate: {
    x: 0,
    transition: {
      ...defaultTransition,
      staggerChildren: 0.2
    }
  },
  exit: {
    x: '100%',
    transition: {
      ...defaultTransition
    }
  }
}
