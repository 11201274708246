import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState
} from 'react'

export interface IContextType {
  selectedPoint: number
  setSelectedPoint: Dispatch<SetStateAction<number>>
  enableDrag: boolean
  setEnableDrag: Dispatch<SetStateAction<boolean>>
}

const AppContext = createContext<IContextType | null>(null)

export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedPoint, setSelectedPoint] = useState<number>(-1)
  const [enableDrag, setEnableDrag] = useState<boolean>(false)

  return (
    <AppContext.Provider
      value={{
        selectedPoint,
        setSelectedPoint,
        enableDrag,
        setEnableDrag
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const useAppContext = (): IContextType => {
  const context = useContext<IContextType | null>(AppContext)

  if (context === undefined || context === null) {
    throw new Error('Context must be used within a Provider')
  }
  return context
}
