import React, { ComponentProps } from 'react'
import './Point.scss'
import { useAppContext } from '../../../context/AppContext'

interface PointProps extends ComponentProps<'button'> {
  point: PointDataType
  index: number
  extraClasses?: string
}

export type PointDataType = {
  top: string
  left: string
  id: string
  left_num: number
}

export const Point = ({ point, index, ...props }: PointProps) => {
  const { top, left } = point

  const { setSelectedPoint } = useAppContext()
  return (
    <button
      {...props}
      title={point.id}
      style={{ top, left }}
      className='point'
      onClick={() => {
        setSelectedPoint(index)
      }}
    ></button>
  )
}
