import React, { useEffect } from 'react'
import { useWindowSize } from 'usehooks-ts'
import { AnimalMap } from './AnimalMap'

import './AnimalMapModule.scss'
import { useAppContext } from '../../context/AppContext'
import plus from '../../Images/plus.svg'
import { AnimatePresence, motion } from 'framer-motion'

import mobile_BG from '../../Images/bg/mobile.jpg'
import tablet_BG from '../../Images/bg/tablet.jpg'
import desktop_BG from '../../Images/bg/desktop.jpg'

function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0
}

export const AnimalMapModule = () => {
  const { width } = useWindowSize()
  const isTouch = (width < 1024 && width > 0) || isTouchDevice()
  const { enableDrag, setEnableDrag } = useAppContext()

  useEffect(() => {
    setEnableDrag(false)
  }, [isTouch])

  return (
    <section className='map-section'>
      {isTouch ? (
        <TouchPlaceHolder />
      ) : (
        <AnimalMap isTouch={isTouch} key={'dekstop-one'} />
      )}
      <AnimatePresence>
        {enableDrag && <AnimalMap isTouch={isTouch} key={'touch-one'} />}
      </AnimatePresence>
    </section>
  )
}

const TouchPlaceHolder = () => {
  const { setEnableDrag } = useAppContext()
  return (
    <div className='touch-placeholder'>
      <div className='background-media-wrapper'>
        <img src={mobile_BG} alt='mobile-bg' className='mobile-bg' />
        <img src={tablet_BG} alt='tablet-bg' className='tablet-bg' />
        <img src={desktop_BG} alt='desktop-bg' className='desktop-bg' />
      </div>

      <motion.div
        onClick={() => {
          setEnableDrag(true)
        }}
        className='map-enter-info'
      >
        <span className='text'>
          Tippen, um den
          <br /> Wildkatzenwald zu entdecken
        </span>
        <motion.button whileTap={{ scale: 0.8 }} className='enter-btn'>
          <img src={plus} />
        </motion.button>
      </motion.div>
    </div>
  )
}
