import React from 'react'
import './Navbar.scss'
import WildCat from '../../Images/logos/Wildkatze.png'
import BUND from '../../Images/logos/BUND_logo_V2.svg'
import NBS from '../../Images/logos/nbs.png'

import { Button } from '../Button/Button'

export const Navbar = () => {
  return (
    <header>
      <nav className='navbar'>
        <div className='text-wrapper'>
          <h1>Warum sind Wildkatzenwälder von morgen so wertvoll?</h1>
        </div>
        <div className='logos'>
          <div className='logo-wrapper'>
            <img className='logo' src={BUND} />
            <a
              className='logo-link'
              rel='noreferrer'
              href='https://www.bund.net/'
              target='_blank'
            />
          </div>
          <div className='logo-wrapper'>
            <img className='logo' src={WildCat} />
            <a
              className='logo-link'
              rel='noreferrer'
              href='https://www.bund.net/themen/tiere-pflanzen/wildkatze/'
              target='_blank'
            />
          </div>
          <div className='logo-wrapper'>
            <img className='logo' src={NBS} />
            <a
              className='logo-link'
              rel='noreferrer'
              target='_blank'
              href='https://www.bmuv.de/programm/bundesprogramm-biologische-vielfalt'
            />
          </div>
        </div>
        <Button
          href='https://www.bund.net/tiere-pflanzen/wildkatze/projekt-wildkatzenwaelder-von-morgen/'
          target='_blank'
          text='Zum BUND'
          rel='noreferrer'
          extraClasses='back-btn'
        />
      </nav>
    </header>
  )
}
