const points = [
    {
    left: "64.89583333%",
    left_num: 64.89583333,
    top: "50.20618557%",
    id: "01-kittens"
},

{
    left: "73.54166667%",
    left_num: 73.54166667,
    top:  "13.19587629%",
    id: "02-bat"
    
},
{
    left: "83.38541667%",
    left_num: 83.38541667,
    top: "17.42268041%",
    id: "03-woodpecker"
},
{
    left: "70.3125%",
    left_num: 70.3125,
    top: "79.17525773%",
    id: "04-dead_wood"
},
{
    left: "30.72916667%",
    left_num: 30.72916667,
    top: "49.48453608%",
    id: "05-forest_edge"
},
{
    left: "36.09375%",
    left_num: 36.09375,
    top: "58.55670103%",
    id: "06-ants"
},
{
    left: "16.354166666%",
    left_num: 16.354166666,
    top: "75.4639175258%",
    id: "07-field"
},
{
    left: "65.72916667%",
    left_num: 65.72916667,
    top: "32.57731959%",
    id: "08-forest_clearing"
},
{
    left: "72.8125%",
    left_num: 72.8125,
    top: "66.59793814%",
    id: "09-stream"
},
{
    left: "30.729166666%",
    left_num: 30.729166666,
    top: "34.329896907%",
    id: "10-humans"
},
]

export default points