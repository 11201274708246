import React from 'react'
import './Footer.scss'
import { useWindowSize } from 'usehooks-ts'

export const Footer = () => {
  const { width } = useWindowSize()

  const isTouch = width < 1024 && width > 0

  const LegalJSX = (
    <div className='legal-wrapper'>
      <ul className='legal'>
        <li>
          <a
            className='link'
            rel='noreferrer'
            target='_blank'
            href='https://www.bund.net/impressum/'
          >
            Impressum
          </a>
        </li>
        <li>
          <a
            rel='noreferrer'
            target='_blank'
            href='https://www.bund.net/themen/tiere-pflanzen/wildkatze/bund-ansprechpartnerinnen/'
            className='link'
          >
            Kontakt
          </a>
        </li>
      </ul>
    </div>
  )

  return (
    <footer className='footer'>
      <div className='top-wrapper'>
        {!isTouch && LegalJSX}
        <div className='footer-description'>
          <p className='description'>
            Das Projekt „Wildkatzenwälder von morgen” wird von Oktober 2022 bis
            Oktober 2028 gefördert im
            <a
              target='_blank'
              rel='noreferrer'
              href='https://www.bmuv.de/programm/bundesprogramm-biologische-vielfalt'
            >
              &nbsp;Bundesprogramm Biologische Vielfalt
            </a>{' '}
            durch das
            <a target='_blank' rel='noreferrer' href='https://www.bfn.de/'>
              &nbsp;Bundesamt für Naturschutz
            </a>{' '}
            mit Mitteln des
            <a target='_blank' rel='noreferrer' href='https://www.bmuv.de/'>
              &nbsp;Bundesministeriums für Umwelt, Naturschutz, nukleare
              Sicherheit und Verbraucherschutz.
            </a>
          </p>
        </div>
        {isTouch && LegalJSX}
      </div>
      <div className='bottom-wrapper'>
        <p className='credits'>
          Webdesign & Development by{' '}
          <a
            rel='noreferrer'
            target='_blank'
            href='https://www.aleksundshantu.com/digitalagentur/interaktive-infografiken/'
          >
            ALEKS & SHANTU
          </a>
        </p>
      </div>
    </footer>
  )
}
