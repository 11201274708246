import React, { useEffect, useRef } from 'react'
import './AnimalMap.scss'
import { useGesture } from '@use-gesture/react'
import { useWindowSize } from 'usehooks-ts'
import ReactPlayer from 'react-player'

import data from '../../data/data'
import { Point } from './Points/Point'
import { useMotionValue, motion } from 'framer-motion'
import { x_variant } from '../../utils/transition'
import { useAppContext } from '../../context/AppContext'

import minus from '../../Images/minus.svg'
import { NavArrow } from './NavArrow/NavArrow'

const calculateSectionSize = ({
  width,
  orginalHeight
}: {
  width: number
  orginalHeight: number
}) => {
  const aspectRatio = 200 / 101
  let scrollDirection: 'X' | 'Y' = 'X'
  let sectionHeight = orginalHeight
  let sectionWidth = sectionHeight * aspectRatio

  if (sectionWidth < width) {
    sectionHeight = width / aspectRatio
    sectionWidth = width
    scrollDirection = 'Y'
  }

  return { sectionHeight, sectionWidth, scrollDirection }
}

interface AnimalMapProps {
  isTouch: boolean
}

export const AnimalMap = ({ isTouch }: AnimalMapProps) => {
  const map = useRef<HTMLDivElement>(null)

  const { height, width } = useWindowSize()
  const orginalHeight = isTouch ? height : height - 85

  const { setEnableDrag } = useAppContext()

  const { sectionHeight, sectionWidth, scrollDirection } = calculateSectionSize(
    {
      width,
      orginalHeight
    }
  )

  const y_limit = -(sectionHeight - orginalHeight)
  const initYPos = scrollDirection === 'Y' ? y_limit / 2 : 0

  const x_point = (-(sectionWidth - width) - sectionWidth * 0.1) / 2
  const x_limit = -(sectionWidth - width)

  const initXPos =
    scrollDirection === 'X' ? (x_limit < x_point ? x_point : x_limit) : 0

  const mapX = useMotionValue(initXPos)
  const mapY = useMotionValue(initYPos)
  const mapScale = useMotionValue(1)

  useEffect(() => {
    const handler = (e: Event) => e.preventDefault()
    document.addEventListener('gesturestart', handler)
    document.addEventListener('gesturechange', handler)
    document.addEventListener('gestureend', handler)
    return () => {
      document.removeEventListener('gesturestart', handler)
      document.removeEventListener('gesturechange', handler)
      document.removeEventListener('gestureend', handler)
    }
  }, [])

  useGesture(
    {
      // onPinch: ({ offset: [d] }) => {
      //   mapScale.set(d)
      // },
      onDrag: ({ offset: [x, y] }) => {
        if (map.current) {
          mapX.set(x)
          mapY.set(y)
        }
      },
      onDragStart: () => {
        if (map.current) {
          map.current.style.cursor = 'grabbing'
        }
      },
      onDragEnd: () => {
        if (map.current) {
          map.current.style.cursor = 'auto'
        }
      }
    },
    {
      target: map,
      eventOptions: { passive: false },
      // pinch: {
      //   scaleBounds: {
      //     min: 1,
      //     max: 2
      //   }
      // },
      drag: {
        bounds: {
          top: y_limit,
          left: x_limit,
          right: 0,
          bottom: 0
        },
        from: () => [mapX.get(), mapY.get()]
      }
    }
  )

  useEffect(() => {
    mapX.set(initXPos)
    mapY.set(initYPos)
  }, [width, height])

  return (
    <motion.div
      data-is-touch={isTouch}
      variants={isTouch ? x_variant : undefined}
      initial='initial'
      animate='animate'
      exit='exit'
      className='animal-map-wrapper'
    >
      <motion.div
        style={{
          width: sectionWidth,
          height: sectionHeight,
          x: mapX,
          y: mapY,
          scale: mapScale
        }}
        ref={map}
        className='animal-map'
      >
        <div className='background-media-wrapper'>
          <ReactPlayer
            url={'./videos/background.mp4'}
            playing
            loop
            muted
            playsinline
            controls={false}
            width={'100%'}
            height={'100%'}
          />
        </div>

        {data.map((point, index) => (
          <Point key={index} point={point} index={index} />
        ))}
      </motion.div>

      <motion.button
        whileTap={{ scale: 0.8 }}
        onClick={() => {
          setEnableDrag(false)
        }}
        className='exit-map'
      >
        <img src={minus} />
      </motion.button>

      <NavArrow
        x_limit={x_limit}
        y_limit={y_limit}
        mapX={mapX}
        mapY={mapY}
        direction={scrollDirection}
      />
    </motion.div>
  )
}
