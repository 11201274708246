import { motion, useMotionValue } from 'framer-motion'
import React, { useState } from 'react'
import ReactPlayer from 'react-player'
import { CopyRight } from '../CopyRight/CopyRight'
import { opacity_variant } from '../../../utils/transition'

import Play from '../../../Images/play.svg'
import Pause from '../../../Images/pause.svg'

import './VideoPlayer.scss'

interface VideoPlayerProps {
  video: {
    video: string
    copyright: string
  }
}

export const VideoPlayer = ({ video }: VideoPlayerProps) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [player, setPlayer] = useState<ReactPlayer | null>(null)
  const [duration, setDuration] = useState<number>(0)
  const progress = useMotionValue('0%')

  const seek = (e: React.MouseEvent) => {
    if (player) {
      const element = e.currentTarget
      const rect = element.getBoundingClientRect()
      const left = e.pageX - rect.left
      const totalWidth = rect.width
      const percentage = left / totalWidth
      const vidTime = Math.round(duration * percentage)
      player.seekTo(vidTime)
    }
  }

  return (
    <motion.div
      data-is-playing={isPlaying}
      className='video-player'
      variants={opacity_variant}
    >
      <ReactPlayer
        url={video.video}
        playing={isPlaying}
        controls={false}
        muted={false}
        width={'100%'}
        height={'100%'}
        onDuration={(duration) => {
          setDuration(Math.trunc(duration))
        }}
        onReady={(player) => setPlayer(player)}
        onEnded={() => {
          setIsPlaying(false)
        }}
        progressInterval={100}
        onProgress={({ playedSeconds }) => {
          progress.set(`${(playedSeconds / duration) * 100}%`)
        }}
      />
      <button
        className='toggle-button'
        onClick={() => {
          setIsPlaying((last) => !last)
        }}
      >
        {isPlaying ? (
          <img src={Pause} alt='pause' />
        ) : (
          <img src={Play} alt='play' />
        )}
      </button>

      <div className='seek-bar' onClick={seek}>
        <motion.span
          style={{
            width: progress
          }}
          className='progress'
          id='progress'
        ></motion.span>
      </div>
      {video.copyright && <CopyRight text={video.copyright} />}
    </motion.div>
  )
}
