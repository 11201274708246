import React from 'react'
import { Navbar } from '../components/Navbar/Navbar'
import { Modal } from '../components/Modal/Modal'
import { Footer } from '../components/Footer/Footer'
import { AnimatePresence, motion } from 'framer-motion'
import { AnimalMapModule } from '../components/AnimalMap/AnimalMapModule'
export default function App() {
  return (
    <AnimatePresence>
      <motion.main>
        <Navbar />
        <AnimalMapModule />
        <Modal />
        <Footer />
      </motion.main>
    </AnimatePresence>
  )
}
