import React, { useState } from 'react'
import './NavArrow.scss'

import Arrow from '../../../Images/bund-arrow.svg'
import { MotionValue, animate, motion } from 'framer-motion'

interface NavArrowProps {
  direction: 'X' | 'Y'
  mapX: MotionValue<number>
  mapY: MotionValue<number>
  x_limit: number
  y_limit: number
}

export const NavArrow = ({
  direction,
  mapX,
  mapY,
  x_limit,
  y_limit
}: NavArrowProps) => {
  const handleLeft = () => {
    if (direction === 'X') {
      const next = mapX.get() + 100
      if (next > 0) {
        animate(mapX, 0)
        setDisableLeft(true)
      } else {
        animate(mapX, next)
        setDisableLeft(false)
        setDisableRight(false)
      }
    } else {
      const next = mapY.get() + 100

      if (next > 0) {
        animate(mapY, 0)
        setDisableLeft(true)
      } else {
        animate(mapY, next)
        setDisableLeft(false)
        setDisableRight(false)
      }
    }
  }

  const handleRight = () => {
    if (direction === 'X') {
      const next = mapX.get() - 100
      if (next < x_limit) {
        animate(mapX, x_limit)
        setDisableRight(true)
      } else {
        animate(mapX, next)
        setDisableRight(false)
        setDisableLeft(false)
      }
    } else {
      const next = mapY.get() - 100
      if (next < y_limit) {
        animate(mapY, y_limit)
        setDisableRight(true)
      } else {
        animate(mapY, next)
        setDisableRight(false)
        setDisableLeft(false)
      }
    }
  }

  const [disableLeft, setDisableLeft] = useState(false)
  const [disableRight, setDisableRight] = useState(false)

  return (
    <div data-direction={direction} className='nav-arrow'>
      <button
        data-is-disabled={disableLeft}
        onClick={handleLeft}
        className='nav-btn left'
      >
        <motion.img src={Arrow} alt='arrow' />
      </button>
      <button
        data-is-disabled={disableRight}
        onClick={handleRight}
        className='nav-btn right'
      >
        <motion.img src={Arrow} alt='arrow' />
      </button>
    </div>
  )
}
