import React, { useEffect } from 'react'
import './AudioButton.scss'

import SpeakerOn from '../../../Images/speaker_icon.svg'
import SpeakerOff from '../../../Images/speaker_stop_icon.svg'
import { AnimatePresence, motion } from 'framer-motion'
import { opacity_variant, opacity_y } from '../../../utils/transition'

interface AudioButtonProps {
  audio: string
}

export const AudioButton = ({ audio }: AudioButtonProps) => {
  const [isOn, setIsOn] = React.useState(true)

  const audioRef = React.useRef<HTMLAudioElement>(null)

  useEffect(() => {
    if (audioRef.current) {
      if (isOn) {
        audioRef.current.currentTime = 0
        audioRef.current.play()
      } else {
        audioRef.current.pause()
      }

      audioRef.current.onended = () => {
        setIsOn(false)
      }
    }
  }, [isOn])

  return (
    <motion.div variants={opacity_y} className='audio-wrapper'>
      <button
        onClick={() => {
          setIsOn((last) => !last)
        }}
        className='audio-button'
      >
        {isOn ? (
          <img src={SpeakerOn} alt='on' />
        ) : (
          <img src={SpeakerOff} alt='off' />
        )}

        <audio ref={audioRef} src={audio} />
      </button>

      <AnimatePresence initial={false} mode='popLayout'>
        {isOn ? (
          <motion.span
            key={'on'}
            variants={opacity_variant}
            className='audio-span'
          >
            Tonspur an
          </motion.span>
        ) : (
          <motion.span
            key={'off'}
            variants={opacity_variant}
            className='audio-span'
          >
            Tonspur aus
          </motion.span>
        )}
      </AnimatePresence>
    </motion.div>
  )
}
