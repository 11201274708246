import React, { useEffect } from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import { DotButton, useDotButton } from './DotButton/DotButton'

import './Slider.scss'
import {
  NextButton,
  PrevButton,
  usePrevNextButtons
} from './ArrowButton/ArrowButton'
import { CopyRight } from '../CopyRight/CopyRight'

interface SliderProps {
  images: { image: string; copyright: string }[]
  enableSlider: boolean
}

export const Slider = ({ images }: SliderProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: false
  })

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)

  useEffect(() => {
    return () => {
      if (emblaApi) {
        emblaApi.destroy()
      }
    }
  }, [emblaApi])

  return (
    <div className='slider'>
      <div ref={emblaRef} className='embla'>
        <div className='embla-container'>
          {images.map((image, index) => (
            <div key={index} className='embla-slide'>
              <img src={image.image} alt={`slide-${index}`} loading='lazy' />
              {image.copyright && <CopyRight text={image.copyright} />}
            </div>
          ))}
        </div>
      </div>
      {scrollSnaps.length > 1 && (
        <div className='slider-action-wrapper'>
          <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
          <div className='bullet-wrapper'>
            {scrollSnaps.map((_, index) => (
              <DotButton
                className={`bullet ${index === selectedIndex ? 'active' : ''}`}
                key={index}
                onClick={() => onDotButtonClick(index)}
              />
            ))}
          </div>
          <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
        </div>
      )}
    </div>
  )
}
