import { Variants } from "framer-motion";

export const defaultEase = [0.455, 0.03, 0.515, 0.955];

export const defaultTransition = {
  ease: defaultEase,
  duration: 1,
};


export const opacity_variant: Variants = {
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1,
    transition: defaultTransition
  },
  exit: {
    opacity: 0,
    transition: defaultTransition
  }
}

export const y_variant: Variants = {
  initial: {
    y: "100%"
  },
  animate: {
    y: 0,
    transition: defaultTransition
  },
  exit: {
    y: "100%",
    transition: defaultTransition
  }
}

export const x_variant: Variants = {
  initial: {
    x: "100%"
  },
  animate: {
    x: 0,
    transition: defaultTransition
  },
  exit: {
    x: "100%",
    transition: defaultTransition
  }
}

export const opacity_y: Variants = {
  initial: {
    y: 200,
    opacity: 0
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: defaultTransition
  }
}